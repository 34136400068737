import {
  Avatar,
  Box,
  Flex,
  Img,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import Dropdown from "components/Form/Select";

import { IUser } from "interfaces/IUser";
import HeaderInside from "layouts/HeaderInside";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { UserService } from "services/UserService";
import Pagination from "components/Pagination";
import acceptContract from "assets/img/acceptContract.svg";
import acceptDocuments from "assets/img/acceptDocuments.svg";
import acceptProposal from "assets/img/acceptProposal.svg";
import acceptRegister from "assets/img/acceptRegister.svg";

import warningContract from "assets/img/warningContract.svg";
import warningDocuments from "assets/img/warningDocuments.svg";
import warningProposal from "assets/img/warningProposal.svg";
import warningRegister from "assets/img/warningRegister.svg";
import { IDocumentData } from "interfaces/IDocuments";
import { DocumentsService } from "services/DocumentsService";
import { useFilters } from "modules/filters/hooks";
import InputWithLabel from "components/Form/input/InputWithLabel";
import ModalWrapper from "components/Modal";
import timer from "assets/img/timer.svg";
import timerActive from "assets/img/timerActive.svg";

import CustomButtom from "components/Form/Button";
import { NotAllowedIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { TimeReportsService } from "services/TimeReportsService";
import months from "helpers/months";
import { useAuth } from "modules/auth/hooks";
import { ITimeReports } from "interfaces/ITimeReports";
import { checkPermission } from "helpers/checkPermission";

export const ListUsers: React.FC = () => {
  const documentService = new DocumentsService();
  const userService = new UserService();
  const timeReportsService = new TimeReportsService();

  const { data, handleSetPayload } = useFilters();

  const [currentTimeReport, setCurrentTimeReport] = useState<ITimeReports>();
  const [openModalLiberationAppoint, setOpenModalLiberationAppoint] =
    useState(false);
  const [isFilteredByisActive, setIsFilteredByIsActive] = useState<
    boolean | undefined
  >(true);

  const user = useAuth();
  const toast = useToast();
  const history = useHistory();
  const [documentsLoaded, setDocumentsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState({
    page: 1,
    totalPages: 1,
  });
  const [usersLoaded, setUsersLoaded] = useState(false);
  const [userSelected, setUserSelected] = useState<IUser>({} as IUser);
  const [documents, setDocuments] = useState<IDocumentData[]>([]);
  const [allUsers, setAllUsers] = useState<IUser[]>([]);
  const [allUsersFiltered, setAllUsersFiltered] = useState<IUser[]>([]);
  const [selectedHour, setSelectedHour] = useState("");
  const [openModalBlockAllAppoint, setOpenModalBlockAllAppoint] =
    useState(false);

  const handleGetDocs = useCallback(async () => {
    try {
      const response: IDocumentData[] = await documentService.getAllDocuments();

      setDocuments(response);
      setDocumentsLoaded(true);
    } catch (err) {
      setDocuments([]);
      setDocumentsLoaded(false);
      toast({
        title: "Ocorreu um erro!",
        description: "Erro buscar os documentos",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddLiberationOfAppoint = useCallback(async () => {
    const date = new Date();

    const hoursToAdd = parseInt(selectedHour, 10);

    date.setHours(date.getHours() + hoursToAdd);

    const userWithHours = { ...userSelected, canReportUntil: date };

    const response = await userService.updateUser(
      userWithHours.id,
      userWithHours,
    );

    if (response.id) {
      handleGetUsers();

      setOpenModalLiberationAppoint(false);
      return toast({
        title: "Dados atualizados!",
        description: `Usuário ${response.firstName} pode criar apontamentos por mais ${hoursToAdd} horas`,
        status: "success",
        duration: 4000,
        isClosable: true,
      });
    }

    setOpenModalLiberationAppoint(false);
    return toast({
      title: "Ocorreu um erro!",
      description: "",
      status: "error",
      duration: 4000,
      isClosable: true,
    });
  }, [userSelected, selectedHour]);

  const paginate = useCallback(
    (data, page, pageSize) => {
      const startIndex = (page - 1) * pageSize;
      const endIndex = startIndex + pageSize;
      const dataSliced = data.slice(startIndex, endIndex);

      const userWihData = dataSliced.map((user) => {
        const userDocuments =
          documents.length > 0 &&
          documents?.filter((doc) => doc.owner.id === user.id);

        return {
          ...user,
          userDocuments: userDocuments || [],
        };
      });

      return userWihData || [];
    },
    [documents],
  );

  const handleGetUsers = useCallback(async () => {
    setLoading(true);

    const response = await userService.getAllUsersOrdered();

    if (response.length > 0) {
      const totalDocuments = response.length;
      const totalPages = Math.ceil(totalDocuments / 20);

      let ordenedValues = response
        ? response.filter((value) => value.firstName)
        : [];

      ordenedValues = ordenedValues.sort((a, b) => {
        const now = new Date();

        const canReportA = a.canReportUntil ? new Date(a.canReportUntil) : null;
        const canReportB = b.canReportUntil ? new Date(b.canReportUntil) : null;

        if (canReportA && canReportB) {
          if (
            canReportA.getTime() < now.getTime() &&
            canReportB.getTime() < now.getTime()
          ) {
            return canReportA.getTime() - canReportB.getTime();
          } else if (canReportA.getTime() < now.getTime()) {
            return -1;
          } else if (canReportB.getTime() < now.getTime()) {
            return 1;
          }
        }

        if (canReportA && canReportA.getTime() >= now.getTime()) return -1;

        if (canReportB && canReportB.getTime() >= now.getTime()) return 1;

        return `${a.firstName} ${a.lastName}`
          .toLowerCase()
          .localeCompare(`${b.firstName} ${b.lastName}`.toLowerCase());
      });

      setCurrentPage({
        ...currentPage,
        totalPages: totalPages,
      });

      handleFilter(data.userFilter || "", ordenedValues, isFilteredByisActive);
      setAllUsers(ordenedValues);
      setUsersLoaded(true);
      setLoading(false);
      return;
    }

    toast({
      title: "Ocorreu um erro!",
      description: "",
      status: "error",
      duration: 4000,
      isClosable: true,
    });

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, paginate, toast]);

  const handleFilter = async (
    searchValue: string,
    users: IUser[],
    active?: boolean,
  ) => {
    if (searchValue) {
      const findedUsers = users.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        const matchesSearch = fullName.includes(
          searchValue.toLocaleLowerCase(),
        );

        if (typeof active === "boolean") {
          const matchesActive = active ? user.isActive : !user.isActive;
          return matchesSearch && matchesActive;
        }
        return matchesSearch;
      });

      const totalDocuments = findedUsers.length;
      const totalPages = Math.ceil(
        20 > totalDocuments ? 1 : totalDocuments / 20,
      );

      setCurrentPage({
        ...currentPage,
        totalPages: totalPages,
      });

      setAllUsersFiltered(paginate(findedUsers, 1, 20));
      return;
    }
    let ordenedValues = users
      ? users.filter((value) => {
          if (typeof active === "boolean") {
            const matchesActive = active ? value.isActive : !value.isActive;

            return matchesActive;
          }
          return value.firstName && value;
        })
      : [];

    ordenedValues = ordenedValues.sort((a, b) => {
      const now = new Date();

      const canReportA = a.canReportUntil ? new Date(a.canReportUntil) : null;
      const canReportB = b.canReportUntil ? new Date(b.canReportUntil) : null;

      if (canReportA && canReportB) {
        if (
          canReportA.getTime() < now.getTime() &&
          canReportB.getTime() < now.getTime()
        ) {
          return canReportA.getTime() - canReportB.getTime();
        } else if (canReportA.getTime() < now.getTime()) {
          return -1;
        } else if (canReportB.getTime() < now.getTime()) {
          return 1;
        }
      }

      if (canReportA && canReportA.getTime() >= now.getTime()) return -1;

      if (canReportB && canReportB.getTime() >= now.getTime()) return 1;

      return `${a.firstName} ${a.lastName}`
        .toLowerCase()
        .localeCompare(`${b.firstName} ${b.lastName}`.toLowerCase());
    });
    const totalDocuments = ordenedValues.length;
    const totalPages = Math.ceil(totalDocuments / 20);

    if (data.userFilter === "") {
      setCurrentPage({
        ...currentPage,
        totalPages: totalPages,
      });
    }

    setAllUsersFiltered(paginate(ordenedValues, currentPage.page, 20));
  };

  const handleCheckIfCanReport = useCallback((user) => {
    const now = new Date();
    const canReportA = user.canReportUntil
      ? new Date(user.canReportUntil)
      : null;

    if (canReportA) {
      if (canReportA.getTime() >= now.getTime()) {
        return true;
      }
    }

    return false;
  }, []);

  const handleBlockOrUnblockTimeReports = useCallback(async () => {
    try {
      const date = new Date();

      if (currentTimeReport?.done) {
        await timeReportsService.updateBlockTimeReports(
          currentTimeReport,
          user.data.id,
        );
      } else {
        await timeReportsService.postBlockTimeReports(
          handleCheckBefore3WorkingDay()
            ? `${date.getFullYear()}${date.getMonth() + 1 < 10 ? `0${date.getMonth()}` : date.getMonth()}`
            : `${date.getFullYear()}${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}`,
          user.data.id,
        );
      }

      toast({
        title: "Dados salvos com sucesso!",
        description: "",
        status: "success",
        duration: 4000,
        isClosable: true,
      });

      setOpenModalBlockAllAppoint(!openModalBlockAllAppoint);
      handleGetIfCurrentMonthIsBlocked();
    } catch (err) {
      toast({
        title: "Ocorreu um erro!",
        description: "",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    }
  }, [currentTimeReport, openModalBlockAllAppoint]);

  const handleCheckBefore3WorkingDay = () => {
    const currentDate = new Date();
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    let workingDaysCount = 0;

    while (workingDaysCount < 3) {
      const dayOfWeek = date.getDay();

      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        workingDaysCount++;
      }

      if (workingDaysCount < 3) {
        date.setDate(date.getDate() + 1);
      }
    }

    return currentDate < date;
  };

  const handleGetIfCurrentMonthIsBlocked = useCallback(async () => {
    const currentTimeReport =
      await timeReportsService.getTimeReportsToCurrentMonth(
        handleCheckBefore3WorkingDay()
          ? `${new Date().getFullYear()}${new Date().getMonth()}`
          : `${new Date().getFullYear()}${new Date().getMonth() + 1}`,
      );

    setCurrentTimeReport(currentTimeReport);
  }, []);

  useEffect(() => {
    handleGetDocs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetIfCurrentMonthIsBlocked();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (documentsLoaded && usersLoaded) {
      handleFilter(data.userFilter || "", allUsers, isFilteredByisActive);
    }
  }, [documentsLoaded, usersLoaded, data.userFilter, currentPage.page]);

  return (
    <>
      <HeaderInside>
        <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
          <Flex
            align={"center"}
            justify={"space-between"}
            gap={3}
            cursor={"pointer"}
            w="100%"
          >
            <Text
              fontSize={["sm"]}
              cursor={"pointer"}
              onClick={() => {
                history.push("users/create/0");
              }}
            >
              {" "}
              + Adicionar novo
            </Text>

            {checkPermission({
              permission: ["super_admin"],
              userRole: user.data.encryptedLoggedUserRoles,
            }) && (
              <Flex
                borderRadius={"4px"}
                cursor={"pointer"}
                py="2"
                px="2"
                border="1px solid"
                borderColor={currentTimeReport?.done ? "red" : "white"}
                bg={currentTimeReport?.done ? "yellowLight" : "transparent"}
                color={currentTimeReport?.done ? "red" : "white"}
                align={"center"}
                justify={"center"}
                gap="1"
                onClick={() =>
                  setOpenModalBlockAllAppoint(!openModalBlockAllAppoint)
                }
              >
                <Img as={NotAllowedIcon} height={"25px"} w="25px" />
                <Text fontSize={["sm"]}>
                  {currentTimeReport?.done
                    ? `Bloqueado até ${months[Number(currentTimeReport.id.substring(4, 6)) - 1]} de ${currentTimeReport.id.substring(0, 4)}`
                    : "Bloquear apontamentos de horas"}{" "}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </HeaderInside>

      <Flex p="5" flexDir={"column"}>
        <Flex
          align={"center"}
          justify={"space-between"}
          gap="3"
          flexDir={["column", "column", "row"]}
        >
          <Flex
            w="100%"
            align={"center"}
            justify={"center"}
            gap={5}
            pos="relative"
          >
            <InputWithLabel
              label="Pesquisar"
              value={data.userFilter || ""}
              onChange={(e) => {
                handleSetPayload({ ...data, userFilter: e.target.value });
              }}
            />
          </Flex>
          <Dropdown
            minW={["100%", "100%", "100%"]}
            maxW={["100%", "100%", "100%"]}
            fontSize={["sm"]}
          >
            <option value="">Ordenar por </option>
            <option value="order">Ordem Alfabética </option>
          </Dropdown>

          <Dropdown
            fontSize={["sm"]}
            onChange={(e: any) => {
              setIsFilteredByIsActive(
                e.target.value === "false"
                  ? false
                  : e.target.value === ""
                    ? undefined
                    : Boolean(e.target.value),
              );
              handleFilter(
                data.userFilter || "",
                allUsers,
                e.target.value === "false"
                  ? false
                  : e.target.value === ""
                    ? undefined
                    : Boolean(e.target.value),
              );
            }}
          >
            <option value="">Filtrar por status </option>
            <option value={"true"} selected>
              Ativados
            </option>
            <option value={"false"}>Desativado</option>
          </Dropdown>
        </Flex>

        <Flex
          flexDir={"column"}
          maxH={[
            "calc(100vh - 320px)",
            "calc(100vh - 340px)",
            "calc(100vh - 400px)",
          ]}
          pr="2"
          overflow={"auto"}
          mt="5"
          gap="3"
        >
          {loading ? (
            <Flex
              minH="calc(100vh - 400px)"
              w="100%"
              align={"center"}
              justify={"center"}
            >
              <Spinner />
            </Flex>
          ) : (
            <>
              {allUsersFiltered?.map((item, index) => {
                const requiredDocs = [
                  "Cartão CNPJ",
                  "Contrato social",
                  "Comprovante residencial",
                  "Identidade ou CNH",
                ];

                const findContractDoc = item?.userDocuments?.find(
                  (item) => item.name === "Contrato Hvar consultoria",
                );

                const findProposalDoc = item?.userDocuments?.find(
                  (item) => item.name === "Proposta Hvar consultoria",
                );
                const findRegister = item?.userDocuments?.find(
                  (item) => item.name === "Identidade ou CNH",
                );

                const hasRequiredDocs = requiredDocs.every((docName) =>
                  item?.userDocuments?.some((doc) => doc.name === docName),
                );

                return (
                  <>
                    <Flex
                      key={index}
                      align="center"
                      justify="space-between"
                      gap="20"
                      bg="grayBg"
                      p="6"
                      minH="70px"
                      maxH="70px"
                      borderRadius="8px"
                      overflowY="hidden"
                      overflowX="auto"
                      transition="opacity 0.15s ease-in-out" // Adiciona a transição
                    >
                      <Flex
                        align="center"
                        justify="space-between"
                        flex="1"
                        _hover={{
                          opacity: 0.5,
                        }}
                        onClick={() => {
                          history.push(`users/create/${item.id}`);
                        }}
                        cursor="pointer"
                      >
                        <Flex align={"center"} gap="2">
                          <Flex pos={"relative"}>
                            <Avatar
                              name={item.firstName || undefined}
                              src={item.photoPath || undefined}
                              size="md"
                              mr="3"
                            />
                            {handleCheckIfCanReport(item) && (
                              <Img
                                pos="absolute"
                                right={"5px"}
                                src={timerActive}
                                width="35px"
                                height={"35px"}
                              />
                            )}
                          </Flex>

                          <Flex
                            flexDir="column"
                            minWidth="170px"
                            flexBasis="0"
                            flexGrow="1"
                            maxWidth="170px"
                          >
                            <Text fontSize="10px" color="grayLight">
                              Nome completo
                            </Text>
                            <Text fontSize="sm" isTruncated>
                              {item.firstName} {item.lastName}
                            </Text>
                          </Flex>
                        </Flex>

                        <Flex
                          flexDir="column"
                          minWidth="150px"
                          flexBasis="0"
                          flexGrow="2"
                          maxWidth="290px"
                        >
                          <Text fontSize="10px" color="grayLight">
                            Email
                          </Text>
                          <Text fontSize="sm" isTruncated>
                            {item.email}
                          </Text>
                        </Flex>

                        <Flex
                          flexDir="column"
                          minWidth="80px"
                          flexBasis="0"
                          flexGrow="1"
                          maxWidth="80px"
                        >
                          <Text fontSize="10px" color="grayLight">
                            Torre
                          </Text>
                          <Text fontSize="sm" isTruncated>
                            {item?.sector?.name}
                          </Text>
                        </Flex>
                      </Flex>
                      <Flex
                        flex="0.5"
                        align="center"
                        justify="space-evenly"
                        gap="3"
                      >
                        <Flex minH="60px" minW="60px">
                          <Img
                            src={
                              findContractDoc ? acceptContract : warningContract
                            }
                          />
                        </Flex>
                        <Flex minH="60px" minW="60px">
                          <Img
                            src={
                              findProposalDoc ? acceptProposal : warningProposal
                            }
                          />
                        </Flex>
                        <Flex minH="60px" minW="60px">
                          <Img
                            src={
                              findRegister ? acceptRegister : warningRegister
                            }
                          />
                        </Flex>
                        <Flex minH="60px" minW="60px">
                          <Img
                            src={
                              hasRequiredDocs
                                ? acceptDocuments
                                : warningDocuments
                            }
                          />
                        </Flex>
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gap={1}
                          flexDir={"column"}
                          cursor={"pointer"}
                          _hover={{
                            opacity: 0.5,
                          }}
                          onClick={() => {
                            setUserSelected(item);
                            setOpenModalLiberationAppoint(true);
                          }}
                        >
                          <Box w="4px" h="4px" bg="black" borderRadius="50%" />
                          <Box w="4px" h="4px" bg="black" borderRadius="50%" />
                          <Box w="4px" h="4px" bg="black" borderRadius="50%" />
                        </Flex>
                      </Flex>
                    </Flex>
                  </>
                );
              })}
            </>
          )}
        </Flex>

        <ModalWrapper
          isOpen={openModalLiberationAppoint}
          onClose={() => {
            setOpenModalLiberationAppoint(!openModalLiberationAppoint);
          }}
          whiteIcon
          borderRadius={"8px"}
          minH="300px"
        >
          <Flex borderRadius={"8px"} flexDir={"column"}>
            <HeaderInside borderRadius={"8px 8px 0 0 "}>
              <Flex justify={"space-between"} align={"center"} flex="1" gap="4">
                <Flex
                  align={"center"}
                  justify={"center"}
                  gap={3}
                  cursor={"pointer"}
                >
                  <Text fontSize={["md"]}>Liberação de apontamento</Text>
                </Flex>
              </Flex>
            </HeaderInside>

            <Flex flexDir="column" p="5">
              <Text>Liberar apontamento por :</Text>

              <Dropdown
                mt="5"
                onChange={(e) => {
                  setSelectedHour(e.target.value);
                }}
              >
                <option>Selecione uma opção</option>
                <option value="6">
                  <Img src={timer} /> 6 Horas
                </option>
                <option value="4">
                  <Img src={timer} /> 4 Horas
                </option>
                <option value="2">
                  <Img src={timer} /> 2 Horas
                </option>
                <option value="1">
                  <Img src={timer} /> 1 Hora
                </option>
              </Dropdown>
              <Flex w="100%" align={"center"} justify={"flex-end"}>
                <CustomButtom
                  mt="12"
                  maxW="120px"
                  bg="orange2"
                  color="white"
                  primaryText="Confirmar"
                  onClick={() => handleAddLiberationOfAppoint()}
                />
              </Flex>
            </Flex>
          </Flex>
        </ModalWrapper>

        <ModalWrapper
          isOpen={openModalBlockAllAppoint}
          onClose={() => {
            setOpenModalBlockAllAppoint(!openModalBlockAllAppoint);
          }}
          minW="450px"
          borderRadius={"8px"}
          minH="300px"
        >
          <Flex
            borderRadius={"8px"}
            p="5"
            justify={"space-between"}
            flexDir={"column"}
            align={"center"}
            gap="3"
          >
            <Text fontSize={"x-large"} color="red">
              Atenção!
            </Text>
            <Text textAlign={"center"}>
              Tem certeza que deseja{" "}
              <Text as="span" fontWeight={"bold"}>
                {currentTimeReport?.done ? "Desbloquear" : "Bloquear"}
              </Text>{" "}
              <br /> o apontamento de horas para{" "}
              <Text as="span" fontWeight={"bold"}>
                {handleCheckBefore3WorkingDay()
                  ? `${months[new Date().getUTCMonth() - 1]} - ${new Date().getFullYear()}`
                  : `${months[new Date().getUTCMonth()]} - ${new Date().getFullYear()}`}{" "}
              </Text>
              ?
            </Text>
            <Flex
              bg="#FBFFC4"
              gap="3"
              p="4"
              align={"center"}
              justify={"space-between"}
            >
              <Img as={WarningTwoIcon} color="red" h={"26px"} w="26px" />
              <Text fontSize={"sm"} color="red">
                Essa ação deixará{" "}
                {currentTimeReport?.done ? "disponivel" : "indisponível"} o
                apontamento de horas referente a data de:{" "}
                <Text as="span" fontWeight={"bold"}>
                  {handleCheckBefore3WorkingDay()
                    ? `${months[new Date().getUTCMonth() - 1]} - ${new Date().getFullYear()}`
                    : `${months[new Date().getUTCMonth()]} - ${new Date().getFullYear()}`}
                </Text>
                . <br />
                {currentTimeReport?.done
                  ? ""
                  : "O desbloqueio precisará ser realizado individualmente por consultor."}
              </Text>
            </Flex>
            <Flex w="100%" align={"center"} justify={"space-evenly"}>
              <CustomButtom
                primaryText="Cancelar"
                bg="orange2"
                color="white"
                onClick={() => {
                  setOpenModalBlockAllAppoint(!openModalBlockAllAppoint);
                }}
              />
              <CustomButtom
                primaryText="Salvar"
                onClick={handleBlockOrUnblockTimeReports}
              />
            </Flex>
          </Flex>
        </ModalWrapper>

        <Pagination
          currentPage={currentPage.page}
          loading={loading}
          onPageChange={(e) => {
            setCurrentPage({ ...currentPage, page: e });
          }}
          totalPages={currentPage.totalPages}
        />
      </Flex>
    </>
  );
};
