import {
  ECollections as coll,
  FirestoreService,
  OAuthService,
  StorageService,
} from "../firebase";
import { User } from "entities/User";

interface USER_FILTERS {
  email?: string;
}

export class UserRepository {
  private firestore: FirestoreService;
  private oauthService: OAuthService;
  private storageService: StorageService;

  constructor() {
    this.firestore = new FirestoreService();
    this.oauthService = new OAuthService();
    this.storageService = new StorageService();
  }

  public async get(id: string): Promise<User> {
    const userDocument = await this.firestore.getDocumentById(coll.USER, id);

    return User.fromJson(userDocument, id);
  }

  public async getFiltered(filter: USER_FILTERS): Promise<User> {
    let userResult;

    if (filter.email) {
      const userDocument = await this.firestore.findDocumentByQuery(
        coll.USER,
        "email",
        "==",
        filter.email,
      );
      userResult = userDocument[0];
    }

    if (userResult) {
      return User.fromJson(userResult.data, userResult.id);
    }
    return {} as User;
  }

  public async update(id: string, updateData: User): Promise<User> {
    updateData.updatedAt = new Date();

    await this.firestore.updateDocument(coll.USER, updateData.toJson(), id);

    return User.fromJson(updateData.toJson(), id);
  }

  public async createUserInAuth(createData: User): Promise<string> {
    const responseUser = await this.oauthService.createUserInOAuth(
      createData.email,
    );

    if (!responseUser.user.uid)
      throw new Error("Erro: cannot create user in auth service");

    return responseUser.user.uid;
  }

  public async deleteDocInBucket(path: string): Promise<void> {
    await this.storageService.deleteDocInBucket(path);
  }

  public async uploadUser(uploadImage: Blob, path: string): Promise<string> {
    const userDocument = await this.storageService.uploadImage(
      uploadImage,
      path,
    );

    return userDocument;
  }

  public async getAll(): Promise<User[]> {
    const users = await this.firestore.getAllDocuments(coll.USER);
    return users.map((user) => User.fromJson(user.data, user.id));
  }
}
