import { UserRepository } from "repositories/UserRepository";
import { IUser } from "../interfaces/IUser";
import { User } from "entities/User";
import { FirestoreRepository } from "repositories/FirestoreRepository";

export class UserService {
  private userRepository = new UserRepository();
  private firestoreRepository = new FirestoreRepository<User>(
    "users",
    User.fromJson,
  );

  convertToInterface(user: User): IUser {
    return <User>{
      id: user.id,
      email: user.email,
      lastName: user.lastName,
      admissionDate: user.admissionDate,
      creationDate: user.creationDate,
      isActive: user.isActive,
      sector: user.sector,
      photoPath: user.photoPath,
      roles: user.roles,
      firstName: user.firstName,
      account: user.account,
      address: user.address,
      benefit: user.benefit,
      benefitStartAt: user.benefitStartAt,
      bornDate: user.bornDate,
      cnpj: user.cnpj,
      cpf: user.cpf,
      equipmentNeeded: user.equipmentNeeded,
      hiringRegime: user.hiringRegime,
      jobRole: user.jobRole,
      manager: user.manager,
      personalEmail: user.personalEmail,
      phoneNumber: user.phoneNumber,
      phoneNumberOptional: user.phoneNumberOptional,
      rg: user.rg,
      salary: user.salary,
      salaryType: user.salaryType,
      startDate: user.startDate,
      withHoldTax: user.withHoldTax,
      canReportUntil: user.canReportUntil,
    };
  }

  convertToEntity(user: IUser): User {
    return new User(
      user.id,
      user.firstName,
      user.lastName,
      user.email,
      user.createdAt,
      user.updatedAt,
      user.isActive,
      user.roles,
      user.sector,
      user.admissionDate,
      user.bornDate,
      user.photoPath,
      user.phoneNumber,
      user.jobRole,
      user.manager,
      user.cpf,
      user.rg,
      user.startDate,
      user.phoneNumberOptional,
      user.personalEmail,
      user.withHoldTax,
      user.salary,
      user.salaryType,
      user.cnpj,
      user.hiringRegime,
      user.creationDate,
      user.account,
      user.address,
      user.benefit,
      user.benefitStartAt,
      user.equipmentNeeded,
      user.canReportUntil,
    );
  }

  public async getUserByEmail(email: string): Promise<IUser | undefined> {
    const user = await this.userRepository.getFiltered({ email: email });
    if (user) return this.convertToInterface(user);
    return undefined;
  }

  public async getUserById(id: string): Promise<IUser | undefined> {
    const result = await this.firestoreRepository.getDocument(id);

    if (result)
      return this.convertToInterface(
        result.data() ? result.data() : ({} as any),
      );
  }

  public async getAllUsersByRoles(roles: string[]): Promise<any[]> {
    const usersSnapshot = await this.firestoreRepository.getDocuments();

    const usersWithRoles = usersSnapshot.docs
      .map((item) => {
        return item.data();
      })
      .filter((userDoc) => {
        const userRoles = userDoc.roles;

        if (!userRoles) return false;

        if (
          roles.some((role) =>
            Object.prototype.hasOwnProperty.call(userRoles, role),
          )
        ) {
          return userDoc;
        }
      });

    return usersWithRoles;
  }

  public async updateUserPhotoPath(user: IUser, photoPath: string) {
    const userConverted = this.convertToEntity({
      ...user,
      photoPath: photoPath,
    });

    await this.firestoreRepository.updateDocument(
      userConverted,
      userConverted.id,
    );
  }

  public async updateUser(id: string, user: IUser): Promise<IUser> {
    const userConverted = this.convertToEntity(user);

    const userUpdated = await this.userRepository.update(id, userConverted);

    return this.convertToInterface(userUpdated);
  }

  public async createUser(user: IUser): Promise<string> {
    user.createdAt = new Date();
    user.updatedAt = new Date();
    user.isActive = true;

    const userConverted = this.convertToEntity(user);
    const userId = await this.userRepository.createUserInAuth(userConverted);

    await this.firestoreRepository.createDocument(userConverted, userId);

    return userId;
  }

  public async deleteDocumentUser(path: string): Promise<void> {
    await this.userRepository.deleteDocInBucket(path);
  }
  public async uploadDocumentUser(img: Blob, path: string): Promise<string> {
    return this.userRepository.uploadUser(img, path);
  }

  public async getAllUsersOrdered(): Promise<IUser[]> {
    const usersResult = await this.firestoreRepository.getDocuments();

    return usersResult.docs
      .sort((a, b) =>
        `${a?.data().firstName} ${a.data().lastName}`?.localeCompare(
          `${b?.data().firstName} ${b.data().lastName}`,
        ),
      )
      .map((user) => user.data());
  }

  public async getAll(): Promise<IUser[]> {
    const usersResult = await this.userRepository.getAll();

    return usersResult
      .sort((a, b) => a?.firstName?.localeCompare(b?.firstName))
      .map((user) => this.convertToInterface(user));
  }
}
