import { initializeApp } from "firebase/app";

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  UserCredential,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

import {
  collection,
  query,
  where,
  getDocs,
  WhereFilterOp,
  getDoc,
  doc,
  setDoc,
  addDoc,
  updateDoc,
  connectFirestoreEmulator,
  deleteDoc,
  initializeFirestore,
} from "firebase/firestore";

import {
  deleteObject,
  getDownloadURL,
  getStorage,
  listAll,
  ref,
  uploadBytes,
} from "firebase/storage";

import { getFunctions, httpsCallable } from "firebase/functions";

const projectId = process.env.REACT_APP_PROJECT_ID;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: projectId + ".firebaseapp.com",
  projectId: projectId,
  storageBucket: "gs://" + projectId + ".appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGIN_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

const app = initializeApp(firebaseConfig);

const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

if (process.env.REACT_APP_NODE_ENV == "local") {
  console.log(
    "64 6F 6E 27 74 20 70 61 6E 69 63 / ENVIROMENT:" +
      process.env.REACT_APP_NODE_ENV,
  );
  connectFirestoreEmulator(db, "127.0.0.1", 8081);
  connectAuthEmulator(getAuth(app), "http://127.0.0.1:9099");
}

const auth = getAuth(app);

if (!auth.currentUser) {
  setPersistence(auth, browserLocalPersistence)
    .then(() => {
      console.log("Persistência configurada com sucesso.");
    })
    .catch((error) => {
      console.error("Erro ao configurar a persistência:", error);
    });
} else {
  console.log(
    "Usuário já autenticado, persistência não configurada novamente.",
  );
}

export enum ECollections {
  USER = "users",
  USER_ROLE = "user_roles",
  SECTORS = "sectors",
  TIMESHEET = "timesheets",
  STATUS_TYPE = "status_types",
  DOCUMENTS = "documents",
  CUSTOMER = "customers",
  PROJECT = "projects",
  PROJECT_TYPE = "project_types",
  ADDITIONAL_TIME_REQUEST = "additional_time_requests",
  TASK = "tasks",
  LOG = "logs",
}

export interface IGetReport {
  year: number;
  month: number;
}

export class OAuthService {
  private auth = getAuth(app);

  provider = new GoogleAuthProvider();

  // TODO: Verificar retorno
  public async singIn(): Promise<any> {
    return await signInWithPopup(this.auth, this.provider);
  }

  public async createUserInOAuth(email: string): Promise<UserCredential> {
    const currentUser = this.auth.currentUser;

    const newUserCredential = await createUserWithEmailAndPassword(
      this.auth,
      email,
      crypto.randomUUID(),
    );

    if (currentUser) {
      await this.auth.updateCurrentUser(currentUser);
    } else {
      await this.auth.signOut();
    }

    return newUserCredential;
  }
}

export class FirestoreService {
  public collection;
  public converter;

  constructor(collection?: ECollections, converter?) {
    this.collection = collection;
    this.converter = converter;
    getAuth(app);
  }

  //    NEW FUNCTIONS WITH CONVERTER

  public async getDocById<T>(docId: string): Promise<T> {
    try {
      const docRef = doc(db, this.collection, docId).withConverter(
        this.converter,
      );
      return (await getDoc(docRef)).data() as T;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async getDocs<T>(filter?: {
    field: string;
    operator: WhereFilterOp;
    value: string | boolean | string[];
  }): Promise<T[]> {
    try {
      let filterData;
      if (filter) {
        filterData = where(filter.field, filter.operator, filter.value);
      }
      const q = query(
        collection(db, this.collection),
        filterData,
      ).withConverter(this.converter);

      const QuerySnapshot = await getDocs(q);
      return QuerySnapshot.docs.map((x) => x.data() as T);
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async setDoc<T>(
    collectionPath: string,
    entity: any, // TODO: Receber uma entidade
    documentId: string,
  ): Promise<any> {
    const document = doc(db, collectionPath, documentId).withConverter(
      this.converter,
    );
    return (await setDoc(document, entity)) as T;
  }

  public async addDoc<T>(collectionPath: string, entity: any): Promise<any> {
    return (await addDoc(
      collection(db, collectionPath).withConverter(this.converter),
      entity,
    )) as T;
  }

  // OLD FUNCTIONS

  public async findDocumentByQuery(
    collectionName: string,
    field: string,
    operator: WhereFilterOp,
    value: string | boolean | string[],
  ): Promise<any[]> {
    try {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef, where(field, operator, value));

      const dataResult: any[] = [];
      const docSnap = await getDocs(q);
      docSnap.forEach((doc) => {
        dataResult.push({ id: doc.id, data: doc.data() });
      });
      return dataResult;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async getDocumentById(collectionName: string, docId: string) {
    try {
      const docRef = doc(db, collectionName, docId);
      const docSnap = await getDoc(docRef);
      return docSnap.data();
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async getAllDocuments(collectionName: string) {
    try {
      const querySnapshot = await getDocs(collection(db, collectionName));
      const documents = querySnapshot.docs.map((doc) => ({
        data: doc.data(),
        id: doc.id,
      }));
      return documents;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async setDocument(
    collectionPath: string,
    data: any,
    documentId?: string,
  ): Promise<any> {
    try {
      const result =
        documentId != undefined
          ? await setDoc(doc(db, collectionPath, documentId), data)
          : await addDoc(collection(db, collectionPath), data);
      return result;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }

  public async updateDocument(
    collectionPath: string,
    data: any,
    documentId: string,
  ): Promise<any> {
    try {
      const documentRef = doc(db, collectionPath, documentId);

      await updateDoc(documentRef, data, {
        merge: true,
      });

      return data;
    } catch (e) {
      throw new Error(`Erro ao atualizar o documento: ${e}`);
    }
  }

  public async updateSubcollectionDocument(
    collectionPath: string,
    documentId: string,
    subcollectionPath: string,
    subdocumentId: string,
    data: any,
  ): Promise<any> {
    try {
      const documentRef = doc(
        db,
        collectionPath,
        documentId,
        subcollectionPath,
        subdocumentId,
      );
      const response = await updateDoc(documentRef, data);
      return response;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  public async createSubcollectionDocument(
    collectionPath: string,
    documentId: string,
    subcollectionPath: string,
    data: any,
  ): Promise<any> {
    try {
      const documentRef = doc(db, collectionPath, documentId);

      const subcollectionRef = collection(documentRef, subcollectionPath);
      const newDocumentRef = await addDoc(subcollectionRef, data);

      return newDocumentRef;
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  public async deleteDocument(
    collectionPath: string,
    documentId: string,
  ): Promise<void> {
    try {
      const documentRef = doc(collection(db, collectionPath), documentId);
      await deleteDoc(documentRef);
    } catch (error) {
      throw new Error(`${error}`);
    }
  }

  public async deleteDocumentVirtually(
    collectionPath: string,
    documentId: string,
  ): Promise<any> {
    try {
      const disabeDoc = { is_active: false };
      const result = updateDoc(doc(db, collectionPath, documentId), disabeDoc);
      return result;
    } catch (e) {
      throw new Error(`${e}`);
    }
  }
}

export class StorageService {
  public async deleteDocInBucket(storagePath: string): Promise<void> {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, storagePath);

      await deleteObject(imageRef);
    } catch (error: any) {
      throw new Error(`Erro ao remover a imagem: ${error.message}`);
    }
  }

  public async uploadImage(
    imageData: Blob,
    storagePath: string,
  ): Promise<string> {
    try {
      const storage = getStorage();
      const hash = this.generateUniqueName();

      const imageRef = ref(storage, `${storagePath}/${hash}`);

      await uploadBytes(imageRef, imageData);

      const downloadURL = await getDownloadURL(imageRef);

      return downloadURL;
    } catch (error: any) {
      throw new Error(`Erro ao enviar a imagem: ${error.message}`);
    }
  }

  public async getImagesInFolder(storagePath: string) {
    const storage = getStorage(app);
    const bucketRef = ref(storage, `/${storagePath}`);

    try {
      const files = await listAll(bucketRef);
      const urlsImagens = await Promise.all(
        files.items.map(async (itemRef) => {
          const url = await getDownloadURL(itemRef);
          return url;
        }),
      );

      return urlsImagens[urlsImagens.length - 1];
    } catch (error) {
      console.error("Erro ao listar arquivos na pasta do bucket:", error);
      // TODO: Trate o erro adequadamente
      throw error;
    }
  }

  public generateUniqueName(): string {
    // Gere um nome de arquivo único usando timestamp e um número aleatório
    const timestamp = new Date().getTime();
    const randomNumber = Math.floor(Math.random() * 10000);
    return `${timestamp}_${randomNumber}`;
  }
}

export class FunctionService {
  public async getReport({ year, month }: IGetReport) {
    const functions = getFunctions();
    const addMessage = httpsCallable(functions, "onReportRequest");
    let response;
    await addMessage({ year: Number(year), month: Number(month) })
      .then((result) => {
        // Read result of the Cloud Function.
        /** @type {any} */
        const data = result.data;

        response = data;
      })
      .catch((error) => {
        // Getting the Error details.
        const code = error.code;
        const message = error.message;
        const details = error.details;

        response = `${code} ${message} ${details}`;
        // ...
      });

    return response;
  }

  public async checkIfExistsReport({ year, month }: IGetReport) {
    const storage = getStorage(app);
    const bucketRef = ref(
      storage,
      `gs://hvar-connect.appspot.com/reports/${year}/${month < 10 ? `0${month}` : month}`,
    );

    try {
      const files = await listAll(bucketRef);

      return files.items;
    } catch (error) {
      console.error("Erro ao listar arquivos na pasta do bucket:", error);
      // Trate o erro adequadamente
      throw error;
    }
  }
}
