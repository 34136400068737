import { useState } from "react";
import {
  Box,
  CheckboxGroup,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Text,
  Flex,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import CustomCheckBox from "../CustomCheckBox";

interface IMultSelect {
  options: {
    id: string;
    label: string;
  }[];
  handleChangeItems: React.Dispatch<React.SetStateAction<string[]>>;
  selectedOptions?: Array<string>;
}

const MultiSelect: React.FC<IMultSelect> = ({
  options,
  handleChangeItems,
  selectedOptions,
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>(
    selectedOptions && selectedOptions?.length > 0 ? selectedOptions : [],
  );

  const handleSelect = (value: string[]) => {
    setSelectedItems(value);
    handleChangeItems(value);
  };

  return (
    <Box borderBottom={"1px solid orange"}>
      <FormControl>
        <FormLabel
          htmlFor="multiSelect"
          position="absolute"
          top={"-10px"}
          // left="10px"
          transition="all 0.2s"
          fontSize={"12px"}
          fontWeight={"normal"}
          pos="absolute"
          bottom="35px"
          color={"grayLight"}
        >
          TAM
        </FormLabel>
        <Menu closeOnSelect={false}>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            float="left"
            p="0"
            w="100%"
            id="multiSelect"
          >
            <Flex w="100%">
              <Text
                maxW="300px"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                fontSize={"sm"}
              >
                {selectedItems.length > 0
                  ? selectedItems.join(", ")
                  : "Selecione uma opção"}
              </Text>
            </Flex>
          </MenuButton>
          <MenuList minWidth="300px" maxW="300px" position="relative">
            <CheckboxGroup value={selectedItems} onChange={handleSelect}>
              <Flex
                flexDir={"column"}
                gap={3}
                p={2}
                maxH="250px"
                overflow={"auto"}
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "#555",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                    borderRadius: "10px",
                  },
                }}
              >
                {options
                  .sort((a, b) => a.label.localeCompare(b.label))
                  .map((option) => (
                    <MenuItem key={option.id}>
                      <label
                        style={{
                          width: "100%",
                          cursor: "pointer",
                        }}
                      >
                        <Flex
                          gap={3}
                          align={"center"}
                          w="100%"
                          justify="space-between"
                        >
                          <Text>{option.label}</Text>
                          <CustomCheckBox value={option.label} />
                        </Flex>
                      </label>
                    </MenuItem>
                  ))}
              </Flex>
            </CheckboxGroup>
            <MenuDivider />
            <MenuItem
              onClick={() => {
                handleChangeItems([]);
                setSelectedItems([]);
              }}
              color="red.500"
              fontWeight="bold"
              p="0"
              px="3"
            >
              Limpar seleção
            </MenuItem>
          </MenuList>
        </Menu>
      </FormControl>
    </Box>
  );
};

export default MultiSelect;
